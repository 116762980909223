import styled from 'styled-components';
import { atMinWidth, breakpoints } from '../../styles/atoms/breakpoints';

export const PaginationStyles = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;

  ${atMinWidth.sm`
    gap: 16px;
  `}

  .page-number-container {
    display: inherit;
    flex-flow: inherit;
    align-items: inherit;
    gap: inherit;
  }

  .link-label {
    display: ${props =>
      props.componentWidth < parseFloat(breakpoints.md) ? 'none' : 'block'};
  }
`;
